<template>
  <ul class="dp-taste-map-legend">
    <li class="dp-taste-map-legend__capsule">
      <Icon name="diamondOff" color="dpBlack01" :width="22" :height="22" />
      <span>
        {{ WORDS.TASTE_MAP.LEGEND_CAPSULE }}
      </span>
    </li>
    <li class="dp-taste-map-legend__coffee">
      <Icon name="startOn" color="dpPrimary" :width="22" :height="22" />
      <span>
        {{ WORDS.TASTE_MAP.LEGEND_RECIPE }}
      </span>
    </li>
  </ul>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup: (props) => {
    const blockName = 'dp-section';
    const titleEnText = computed(() => (props.titleEn ? props.titleEn : ''));
    const titleJpText = computed(() => (props.titleJp ? props.titleJp : ''));

    const classes = computed(() => {
      return [blockName];
    });

    return {
      WORDS,
      titleEnText,
      titleJpText,
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-taste-map-legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin: 0 auto;
  max-width: 343px;

  > li {
    display: grid;
    place-items: center start;
    grid-template-columns: 22px 1fr;
    gap: 4px;
    font-size: 12px;
    line-height: 1.17;
    text-align: left;
  }

  &__coffee {
    color: variables.$dpPrimary;
  }
}
</style>
