var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "dp-taste-map-legend" }, [
    _c(
      "li",
      { staticClass: "dp-taste-map-legend__capsule" },
      [
        _c("Icon", {
          attrs: {
            name: "diamondOff",
            color: "dpBlack01",
            width: 22,
            height: 22,
          },
        }),
        _c("span", [
          _vm._v(" " + _vm._s(_vm.WORDS.TASTE_MAP.LEGEND_CAPSULE) + " "),
        ]),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "dp-taste-map-legend__coffee" },
      [
        _c("Icon", {
          attrs: { name: "startOn", color: "dpPrimary", width: 22, height: 22 },
        }),
        _c("span", [
          _vm._v(" " + _vm._s(_vm.WORDS.TASTE_MAP.LEGEND_RECIPE) + " "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }